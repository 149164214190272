/**
 слайдер
 */
import React from "react";
import Slider from "react-slick";

const SlickSlider = ({children, settings}) => {

  return (
    <div className="slider-section ">
      {/**Слайдер*/}
      <Slider {...settings}>
        {children}
      </Slider>

    </div>
  );
}

export default SlickSlider;